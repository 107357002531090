.paginationdot{
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 300;
    position: fixed;
    right: 1%;
    top: 45%;
}

.paginationdot.invisible{
    display:none;
}

.home_swiper button{
    outline: none;
}