
.swiper_slide{
    height: 450px;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
}

.swiper_slide_right{
    height: 450px;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
}

.swiper_slide_frame{
    width: 50%;
    height: 100%;
    padding: 0% 5% 0% 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swiper_slide_frame2{
    width: 50%;
    height: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swiper_slide_image{
    width: 80%;
}

.swiper_slide_frame h1{
    width: 100%;
    font-size: 250%;
}

.swiper_slide_frame h4{
    color: white;
    font-size: 150%;
    width: 100%;
}

.swiper_slide_headings_black h4{
    color: black;
    width: 100%;
}

.swiper_slide_headings{
    margin-bottom: 2%;
}

.swiper_slide_buttons{
    margin-top: 8%;
    display: flex;
}

.mainimage_transparent_button{
    width: 40%;
    /* padding: 10px; */
    background-color: transparent;
    font-size: 99%;
    border-color: transparent;
    /* border-radius: 100px; */
    color: white;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    font-size: 100%;
    align-items: center;
    text-align: center;
}

.mainimage_transparent_button:hover {
    background-color: #F3913D;
    color: white
}

.swiper_button{
    margin: 0% 2%;
}

.swiper_mainimage_button{
    width: 40%;
    padding: 10px;
    background-color: white;
    font-size: 20px;
    border-radius: 100px;
    color: #F3913D;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    font-size: 100%;
    align-items: center;
    text-align: center;
    border-color: #F3913D;
}
.swiper_mainimage_button:hover {
    background-color: #F3913D;
    color: white;
    border-color: #F3913D;
}

.swiper_mainimage_button_selected{
    width: 40%;
    pointer-events: none;
    padding: 10px;
    background-color: #F3913D;
    font-size: 20px;
    border-radius: 100px;
    color: white;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    font-size: 100%;
    align-items: center;
    text-align: center;
    border-color: #F3913D;
}

.swiper_mainimage_button_selected:focus{
    outline: none;
}

.swiper_mainimage_button:focus{
    outline: none;
}

.black_text{
    color: black;
}

@media (max-width:700px){

    .swiper_mainimage_button{
        font-size: 75%;
        width: 40%;
    }

    .swiper_slide_buttons{
        margin-top: 3%;
        justify-content: center;
    }

    .swiper_slide{
        flex-direction: column;
    }

    .swiper_slide_right{
        flex-direction: column;
    }

    .swiper_slide_frame{
        width: 100%;
        text-align: center;
    }

    .swiper_slide_frame2{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .swiper_slide_image{
        width: 50%;
        margin-top: -5%;
    }

    .swiper_slide_frame h1{
        font-Size:200%;
        color: black
    }
    
    .swiper_slide_frame h4{
        color: white;
        font-size: 115%;
        width: 100%;
    }
    
    .swiper_slide_headings_black h4{
        color: black;
        width: 100%;
    }

    .mainimage_transparent_button{
        font-size: 80%;
    }
}

/* @media (max-width:500px){
    .swiper_slide_image{
        width: 70%;
        margin-top: -10%;
    }
} */
