.feelthevoice{
    margin: 0;
}

.cards_container{
    display: flex;
    justify-content: space-around;
}

@media(max-width:700px){
    .cards_container{
        display: flex;
        flex-direction: column;
    }
}

.card_outline{
    width: 20%;
    border:0.1px solid rgba(0,0,0,0.1);
    background: white;
    display: flex;
    flex-direction: column;
    align-items:center;
    padding: 1%;
    transition: transform 0.3s ease-out;
}

@media (max-width:700px){
    .card_outline{
        width: 100%;
        flex-direction: row;
        padding: 20px 20px 0px 0px;
    }
}


.description{
    color: grey;
    font-style: italic;
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
}

.card_outline:hover{
    border-color: #F3913D;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    transition: transform 0.3s ease-out;
    transform: scale(1.05)
}

.card_outline p{
    font-size: 90%;
    color: grey;
}

.card_image_container{
    display: flex;
    flex-direction: column;
    align-items:center;
}

#card_name{
    font-weight: bold;
    margin-top: 10%;
}

@media(max-width:700px){
    #card_name{
        font-size: 70%;
    }
}

@media(max-width:700px){
    .card_image_container{
        width:70%;
    }
}

.card_avatar_image{
    width:40%;
    border-radius: 50%;
}

.card_text_div{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tryout_button{
    padding: 2px 20px;
    border-radius: 10px;
    background-color: #F3913D;
    font-size: 90%;
    color: white;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    outline: none;
}

@media(max-width:700px){
    .tryout_button{
        white-space: nowrap;
        padding: 5px 20px;
    }
}

.tryout_button:hover {
    background-color: white;
    color: #F3913D
}

.tryout_button:focus{
    outline: none;
}