.audit_container {
    width: 100%;
    height: 93vh;
    display: flex;
    flex-direction: column;
    /* background: rgb(253,238,224);
    background: linear-gradient(180deg, rgba(253,238,224,1) 0%, rgba(255,255,255,1) 100%); */
}

.audit_headingdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 3%;
    padding-right: 13%;
    padding-left: 13%;
    padding-bottom: 3%;
    background: rgb(253,238,224);
    background: linear-gradient(180deg, rgba(253,238,224,1) 0%, rgba(255,255,255,1) 100%);
}

.audit_mainHeading {
    margin-left: 5px;
    font-size: 35px;
    /* font-family: Arial, Helvetica, sans-serif; */
    justify-content: center;
    display: flex;
    font-weight: 300;
    color: grey;
}

.audit_createnew {
    padding: 1% 3%;
    background-color: white;
    border: 1px solid #F3913D;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    color: grey;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease-out;
}
  
.audit_createnew:hover {
    transition: transform 0.3s ease-out;
    background: #F3913D;
    color: white;
}

.audit_blipstitle {
    padding-top: 5%;
    padding-left: 13%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 25px;
    color: rgb(75, 71, 71);
}
.audit_blips_ListingDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 13%;
    padding-left: 13%;
}

.audit_emptycontainer{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(128, 128, 128,0.5);
  }

  .audit_emptycontainer h1{
    font-weight: bold;
    margin-bottom: 2%;
  }

  .audit_emptycontainer h4{
    font-weight: 300;
    display: flex;
  }

  .audit_blipscontainer{
    width: 100%;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: lightgrey;
    padding-top: 3%;
  }

  .audit_blipHeadRow{
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: 1px solid lightgray;
      padding: 0.5% 1%;
      background: white;
  }

  .headercaption{
      width: 15%;
      color: gray;
      text-transform: uppercase;
      font-size: 90%;
  }

  .headercaption_filler{
      color: transparent;
  }

  .audit_blipdatarow{
      margin-top: 1px;
      width: 100%;
      border: 1px solid rgb(219, 215, 215);
      background: white;
      padding: 0.5% 1%;
  }

.audit_blipRowcontainer{
    padding: 2% 0%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
}

.audit_blipRowcontainer:hover{
    background: rgb(241, 239, 239);
}

.audit_blipRowcontainer_border:hover{
    background: rgb(241, 239, 239);
    align-items: center;
}

.audit_blipRowcontainer_border{
    padding: 2% 0%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: lightgray
}

.audit_blipcontainer{
    background: red
}

.audit_blipcontainer:hover{
    background: rgb(248, 247, 247);
}
  
.audit_blipdata{
    width: 15%;
    font-size: 101%;
    color:rgb(95, 174, 248);
    display: flex;
    text-align: center;
    justify-content: center;
}

.audit_bliptextdata{
    width: 70%;
    padding-left: 1%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-content: center;
    font-size: 101%;
    color: rgb(77, 75, 75);
    
}

.audit_blipRowcontainer_border:hover .audit_bliptextdata{
    text-align: left;
    overflow: visible;
    text-overflow: unset;
    white-space: normal;
    font-size: 101%;
}
.audit_blipRowcontainer:hover .audit_bliptextdata{
    text-align: left;
    overflow: visible;
    text-overflow: unset;
    white-space: normal;
    font-size: 101%;
}

.audit_titleblipdata{
    width: 30%;
    font-size: 101%;
}

.audit_playbutton{
    padding:5% 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    box-shadow: 0px 0px 5px 3px rgba(243,145,61,0.43);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.audit_bottomcontainer{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 1% 0% 5% 0%;
}

.audit_pagelink{
    color: rgb(17, 117, 211);
    font-weight: 500;
    margin: 0% 0.5%;
    cursor: pointer;
}

.selected_audit_pagelink{
    color: blue;
    font-weight: 500;
    margin: 0% 0.5%;
    pointer-events: none;
    text-decoration: underline;
}

.audit_pagelink:hover{
    text-decoration: underline;
}

.audit_recordmodal_textbox{
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100%;
    padding: 2%;
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}