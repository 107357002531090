.dot{
    width:10px;
    height: 10px;
    border-radius: 100px;
    background: #D3D3D3;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
    align-self: flex-end;
}

.dot.selected{
    background: #696969;
}