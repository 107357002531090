/* ////////////////// Projects Page CSS ////////////////////////// */

.projects_MainStyle {
  width: 100%;
    display: flex;
    flex-direction: column;
  }
  .projectspage_Headingpart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 3%;
    padding-right: 13%;
    padding-left: 13%;
    padding-bottom: 6%;
    background: rgb(253,238,224);
background: linear-gradient(180deg, rgba(253,238,224,1) 0%, rgba(255,255,255,1) 100%);
  }
  .projectspage_mainHeading {
    font-size: 40px;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-weight: 500;
    color: #F3913D;
  }
  .projectspage_createnew {
    background-color: white;
    border: 1px solid #F3913D;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    color: black;
    padding: 10%;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease-out;
    width: 120%;
  }
  
  .projectspage_createnew:hover {
    transition: transform 0.3s ease-out;
    background: #F3913D;
    color: white;
  }
  .projectpage_YourProjects {
    /* padding-top: 5%; */
    padding-left: 13%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 25px;
    color: rgb(75, 71, 71);
  }
  .projectspage_secoundSectionmain {
    background-color: #fff;
    padding-bottom: 5%;
  }
  .projectpage_SingleProjectMain {
    margin: 10px;
    width: 30%;
  }
  .projectpage_SingleProjectName {
    background-color: #c5e3e6;
    padding: 2%;
    border-radius: 3px;
  }
  .projectspage_allprojectsListingDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 13%;
    padding-left: 13%;
  }

  .projects_emptycontainer{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(128, 128, 128,0.5);
  }

  .projects_emptycontainer h1{
    font-weight: bold;
    margin-bottom: 2%;
  }

  .projects_emptycontainer h4{
    font-weight: 300;
    display: flex;
  }

  .projetcspage_projects_container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .projectspage_project_container{
    width: 45%;
    background: white;
    /* box-shadow: 0px 0px 5px 0px #F3913D; */
    background: #fad3b1;
    padding: 1%;
    border-radius: 3%;
    margin-bottom: 5%;
  }

  .projectspage_project_infocontainer{
    background: #fff;
    padding: 2% 2% 3% 2%;
    border-radius: 3%;
  }

  .projectspage_project_smallcaption{
    width: 25%;
    color: grey;
  }

  .projectspage_project_smalltext{
    width: 75%;
    font-size: 90%;
    /* color: #2E207C; */
  }

  .projectspage_project_statustext{
    text-transform:uppercase;
    font-weight: 800;
    /* color: #2E207C; */
  }

  .projects_project_button{
    background:white ;
    color: #2E207C;
    border: 1px solid;
    padding: 1% 1%;
    border-radius: 5px;
    font-size: 80%;
    height: 10%;
    width: 25%;
    cursor: pointer;
    text-align: center;
  }

  .projects_project_button:hover{
    background:#2E207C ;
    color: #fff;
  }

  .indexedStep {
    color: white;
    width: 12px;
    height: 12px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color:#54B749;
  }