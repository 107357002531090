#applications{
    padding-bottom: 5%;
}

.application_card_outline{
    padding: 0;
    background: white;
}

.application_card_outline:hover{
    border-color: white;
}

#applications_slide_container{
    display: flex;
    width: 100%;
    padding: 1% 0%;
    justify-content: space-around;
}


#card_image_container{
    border-bottom: 0px solid orange;
    height: 50%;
}

#card_image{
    width: 100%;
    object-fit: cover;
}

#card_info_container{
    padding: 10%;
}

#card_info_title{
    color: orange;
    font-weight: bold;
    text-shadow:1px 1px #1d1c1c;
}

#card_info_desc{
    color: rgb(85, 83, 83);
}

@media(max-width:700px){
    #applications{
        padding:0 5%;
    }

    #applications_slide_container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0%;
        justify-content: center;
    }

    .application_card_outline{
        margin:2% 0;
        padding: 0;
        background: white;
    }

    #card_image_container{
        border-bottom: 0px solid orange;
        width: 100%;
    }

    #card_image{
        height: 100%;
        object-fit: cover;
    }

    #card_info_container{
        background: white;
        width:100%;
    }
}
