.blip {
  width: 100%;
  height: 93vh;
  display: flex;
  flex-direction: column;
  /* background: rgb(253,238,224);
background: linear-gradient(180deg, rgba(253,238,224,1) 0%, rgba(255,255,255,1) 100%); */
}

.blip_modal{
  align-items: center;
  text-align: center;
}

.report_container{
  text-align: center;
  background: white;
  width: 100%;
  height: 100%;
  max-height: 55%;
  padding: 2%;
  /* box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1); */
}


.blip_headingdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3%;
  padding-right: 13%;
  padding-left: 13%;
  padding-bottom: 3%;
  background: rgb(253, 238, 224);
  background: linear-gradient(
    180deg,
    rgba(253, 238, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.blip_mainHeading {
  font-size: 35px;
  /* font-family: Arial, Helvetica, sans-serif; */
  display: flex;
  font-weight: 300;
  color: grey;
}

.blip_download {
  padding: 1% 3%;
  background-color: white;
  border: 1px solid #f3913d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 90%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: grey;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.blip_download:hover {
  transition: transform 0.3s ease-out;
  background: #f3913d;
  color: white;
}

.blip_container {
  width: 100%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: lightgrey;
  padding-top: 3%;
  border-radius: 5px;
}

.blip_infocontainer {
  padding: 0% 2%;
}

.blip_buttons_container {
  display: flex;
  width: 100%;
  padding: 2% 0%;
}

.blip_button {
  width: 12%;
  text-align: center;
  padding: 0.5% 3%;
  margin-left: 2%;
  border: 1px solid #f3913d;
  color: grey;
  border-radius: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}

.blip_button:hover {
  background: #f3913d;
  color: white;
}



.modal_report_button {
  width: 15%;
  text-align: center;
  padding: 0.5% 3%;
  margin-left: 42%;
  margin-top: 20px;
  border: 1px solid #f3913d;
  color: grey;
  border-radius: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}

.modal_report_button:hover {
  background: #f3913d;
  color: white;
}

/* .modal_report_button_disabled{
  background: #f9c89e;
  -webkit-transition-duration: 0.4s; // Safari 
  transition-duration: 0.4s;
  margin: 10% 0% 0% 0%;
  padding: 3%;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
  border: 1px solid #F3913D;
} */

.blip_report {
  width: 10%;
  text-align: center;
  font-size: 90%;
  padding: 0.5%;
  margin-left: 1%;
  margin-top: 0.8%;
  /* border: 1px solid #F3913D; */
  color: grey;
  /* border-radius: 30px; */
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}

.blip_report:hover {  
    color: #f3913d;
}

.blip_reported {
  width: 15%;
  text-align: center;
  font-size: 90%;
  padding: 0.5%;
  margin-left: 1%;
  margin-top: 0.8%;
  /* border: 1px solid #F3913D; */
  color: grey;
  /* border-radius: 30px; */
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  /* cursor: pointer; */
}

.blip_audiocontainer {
  width: 100%;
  padding: 2%;
  margin-top: 3%;
  background: white;
  border-radius: 10px;
}

.blip_textarea {
  background: white;
  overflow-wrap: break-word;
  margin-top: 2%;
  margin-left: 2%;
  padding: 2%;
  /* border: 1px solid lightgrey; */
  border-radius: 10px;
  font-size: 150%;
  font-weight: 300;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
}

#blip_audio {
  width: 100%;
}
