.projectcreate {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100%;
    padding-top: 9%;
  }
  .projectcreate_FirstRow {
    width: 100%;
    background-color: #ffffff;
    padding: 15% 0% 12% 0%;
    display: flex;
    flex-direction: column;
    text-justify: center;
    /* background: rgb(243,145,61); */
    /* background: linear-gradient(180deg, rgba(243,145,61,1) 0%, rgba(249,202,162,1) 0%, rgba(252,225,203,1) 0%, rgba(253,232,215,1) 0%, rgba(253,235,220,1) 0%, rgba(255,255,255,1) 100%); */
    background: rgb(243,145,61);
    background: linear-gradient(180deg, rgba(243,145,61,1) 0%, rgba(251,215,185,1) 0%, rgba(254,242,233,1) 0%, rgba(254,245,239,1) 0%, rgba(255,255,255,1) 59%, rgba(254,240,228,1) 100%, rgba(253,234,218,1) 100%, rgba(249,200,158,1) 100%, rgba(243,145,61,1) 100%);
    /* box-shadow: 1px 7px 10px 10px rgba(0,0,0,0.1); */
    /* box-shadow: 0px 0px 3px 0px #F3913D; */
  }
  .projectcreate_SecoundRow {
    background-color: #ffffff;
    margin: -31% 10% 0% 55%;
    box-shadow: 0px 0px 3px 0px #F3913D;
  }
  .projectcreate_SecoundRow input:focus {
    outline: none;
  }
  .projectcreate_createprojectStyle {
    padding-left: 14%;
    font-size: 250%;
    font-weight: bolder;
    color: #F3913D;
  }
  .projectcreate_FormMainStyle {
    padding: 5% 5% 5% 5%;
    display: flex;
    flex-direction: column;
    text-justify: center;
    justify-content: center;
  }
  .projectcreate_ProjectName_Inputid {
    border: none;
    border-bottom: 1px solid;
    width: 100%;
    margin-left: 4%;
    font-size: 19px;
  }
  .projectcreate_Projectlanguage_Inputid {
    border: none;
    border-bottom: 1px solid;
    width: 100%;
    font-size: 19px;
  }
  .projectcreate_ProjectName_TextStyle {
    font-size: 120%;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    color: #09282b;
  }
  .projectcreate_inputfeildSameline {
    display: flex;
    flex-direction: row;
    padding-left: 6%;
    margin-left: 6%;
    margin-bottom: 5%;
  }
  .projectcreate_ProjectName_InputStyle {
    padding-left: 4.5%;
    margin-top: -2%;
  }
  .projectcreate_ProjectLang_InputStyle {
    margin-top: -2%;
    padding-left: 10%;
  }
  .projectcreate_submitButtonStyle {
    background-color: #F3913D;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5%;
    font-size: 19px;
    color: rgb(255, 255, 255);
    border-radius: 2px;
    font-weight: 500;
    margin: 5% 5% 5% 5%;
    cursor: pointer;
    transition: transform 0.3s ease-out;
  }

  .disabled_projectcreate_submitButtonStyle {
    background: #f9c89e;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5%;
    font-size: 19px;
    color: rgb(255, 255, 255);
    border-radius: 2px;
    font-weight: 500;
    margin: 5% 5% 5% 5%;
    transition: transform 0.3s ease-out;
  }
  .projectcreate_submitButtonStyle:hover {
    transition: transform 0.3s ease-out;
    transform: scale(1.01);
  }
  .projectcreate_SecoundRow_Discribtion {
    display: flex;
    justify-content: center;
    padding-top: 10%;
    flex-direction: column;
    align-items: center;
  }
  .projectcreate_boo2audio {
    color: #da8337;
    font-size: 200%;
    font-weight: 650;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .projectcreate_boo2audio_Quote {
    color: #162e2e;
    font-size: 100%;
    font-weight: 500;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .projectcreate_dropsection{
    align-self:center;
    margin-left: 18%;
    margin-top: 2%;
    margin-bottom: 8%;
    width: 95%;
  }

  .projectcreate_droptext{
    display: flex;
    justify-content: flex-start;
    width: 90%;
  }

  .projectcreate_dropbutton{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: lightgray;
}

  