.market{
    width: 100%;
    height: 100%;
    /* background: rgb(253,238,224);
    background: linear-gradient(180deg, rgba(253,238,224,1) 0%, rgba(255,255,255,1) 100%); */
}

.buy{
    width: 100%;
    height: 100%;
    padding-bottom: 3%;
}

.buy_section_container{
    width: 100%;
    height: 100%;
    padding: 5% 10% 5% 10%; 
}

.buy_sectioncaption{
    font-weight: bold;
}

.buy_horizontal_divider{
    width: 100%;
    border: 1px solid;
    border-color: rgb(228, 221, 221);
    margin: 3% 0%;
}

.buy_popular_cardoutline{
    width: 30%;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

.buy_popular_imagecontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    /* position: relative; */
    background: #F3913D
}

.buy_popular_backgroundimage{
    width: 80%;
    object-fit: cover;
    -webkit-filter: blur(8px);
}

.buy_popular_image{
    width: 50%;
    object-fit: cover;
    /* position: absolute; */
    border-radius: 150px;
}

.buy_popular_infocontainer{
    width: 100%;
    height: 40%;
    padding:5%;
}

.buy_popular_title{
    font-weight: bold;
}

.buy_popular_modal{
    display: flex;
    color: rgb(87, 85, 85);
}

.buy_popular_audio{
    background: red
}

.buy_searchheader{
    background: rgb(255, 153, 65);
    padding: 1.2% 2%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buy_searchheader_text{
    color: white;
}

.buy_search_input{
    width: 70%;
    outline: none;
}

.buy_searchheader_button{
    border: 1px solid white;
    padding: 0.5% 3%;
    border-radius: 100px;
    cursor: pointer;
    color: white;
}

.buy_searchheader_button:hover{
    background: white;
    color: #F3913D;
}

.buy_searchbody{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buy_searchbody_leftsection{
    width: 20%;
    height: 100%;
    background: rgb(253,238,224);
    background: linear-gradient(180deg, rgba(253,238,224,1) 0%, rgba(255,255,255,1) 100%);
    border-radius: 10px;
    padding: 2%;
}

.buy_searchbody_Rightsection{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
}

.buy_searchbody_leftcaption{
    font-weight: bold;
    /* color: white; */
}

.buy_searchbody_leftoption{
    margin-left: 5%;
    margin-top: 3%;
    cursor: pointer;
    color:#F3913D;
}

.buy_searchbody_leftoption:hover{
    text-decoration: underline;
    color: #2E207C;
}

.buy_searchbody_context{
    width: 30%;
    height: 16.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1%;
    border-radius: 10px;
    font-weight: bold;
    color: grey;
    cursor: pointer;
    transition: transform 0.3s ease-out;
}

.buy_searchbody_context:hover{
    opacity: 0.8;
    /* color: black; */
}

.buy_searchbody_context_1{
    /* background: -webkit-linear-gradient(311deg, rgb(58, 96, 207) 0%, rgb(103, 106, 247) 61%, rgb(126, 99, 247) 100%);
    background: -o-linear-gradient(311deg, rgb(58, 96, 207) 0%, rgb(103, 106, 247) 61%, rgb(126, 99, 247) 100%);
    background: -ms-linear-gradient(311deg, rgb(58, 96, 207) 0%, rgb(103, 106, 247) 61%, rgb(126, 99, 247) 100%);
    background: -moz-linear-gradient(311deg, rgb(58, 96, 207) 0%, rgb(103, 106, 247) 61%, rgb(126, 99, 247) 100%);
    background: linear-gradient(139deg, rgb(58, 96, 207) 0%, rgb(103, 106, 247) 61%, rgb(126, 99, 247) 100%); */
    border: 2px solid rgb(58, 96, 207);
}

.buy_searchbody_context_1:hover{
    color: rgb(58, 96, 207);
    transition: transform 0.3s ease-out;
    transform: scale(1.02)
}

.buy_searchbody_context_2{
    /* background: -webkit-linear-gradient(285deg, rgb(84, 194, 189) 0%, rgb(95, 215, 187) 100%);
    background: -o-linear-gradient(285deg, rgb(84, 194, 189) 0%, rgb(95, 215, 187) 100%);
    background: -ms-linear-gradient(285deg, rgb(84, 194, 189) 0%, rgb(95, 215, 187) 100%);
    background: -moz-linear-gradient(285deg, rgb(84, 194, 189) 0%, rgb(95, 215, 187) 100%);
    background: linear-gradient(165deg, rgb(84, 194, 189) 0%, rgb(95, 215, 187) 100%); */
    border :2px solid rgb(95, 215, 187);
}

.buy_searchbody_context_2:hover{
    color: rgb(95, 215, 187);
    transition: transform 0.3s ease-out;
    transform: scale(1.02)
}

.buy_searchbody_context_3{
    /* background: -webkit-linear-gradient(287deg, rgb(238, 95, 124) 0%, rgb(237, 72, 117) 100%);
    background: -o-linear-gradient(287deg, rgb(238, 95, 124) 0%, rgb(237, 72, 117) 100%);
    background: -ms-linear-gradient(287deg, rgb(238, 95, 124) 0%, rgb(237, 72, 117) 100%);
    background: -moz-linear-gradient(287deg, rgb(238, 95, 124) 0%, rgb(237, 72, 117) 100%);
    background: linear-gradient(163deg, rgb(238, 95, 124) 0%, rgb(237, 72, 117) 100%); */
    border:2px solid rgb(238, 95, 124);
}

.buy_searchbody_context_3:hover{
    color: rgb(238, 95, 124);
    transition: transform 0.3s ease-out;
    transform: scale(1.02)
}

.buy_searchbody_context_4{
    /* background: -webkit-linear-gradient(284deg, rgb(245, 172, 132) 0%, rgb(249, 200, 121) 100%);
    background: -o-linear-gradient(284deg, rgb(245, 172, 132) 0%, rgb(249, 200, 121) 100%);
    background: -ms-linear-gradient(284deg, rgb(245, 172, 132) 0%, rgb(249, 200, 121) 100%);
    background: -moz-linear-gradient(284deg, rgb(245, 172, 132) 0%, rgb(249, 200, 121) 100%);
    background: linear-gradient(166deg, rgb(245, 172, 132) 0%, rgb(249, 200, 121) 100%); */
    border: 2px solid rgb(245, 172, 132);
}

.buy_searchbody_context_4:hover{
    color: rgb(245, 172, 132);
    transition: transform 0.3s ease-out;
    transform: scale(1.02)
}

.buy_searchbody_context_5{
    /* background: -webkit-linear-gradient(284deg, rgb(238, 78, 86) 0%, rgb(239, 102, 101) 61%, rgb(239, 105, 100) 100%);
    background: -o-linear-gradient(284deg, rgb(238, 78, 86) 0%, rgb(239, 102, 101) 61%, rgb(239, 105, 100) 100%);
    background: -ms-linear-gradient(284deg, rgb(238, 78, 86) 0%, rgb(239, 102, 101) 61%, rgb(239, 105, 100) 100%);
    background: -moz-linear-gradient(284deg, rgb(238, 78, 86) 0%, rgb(239, 102, 101) 61%, rgb(239, 105, 100) 100%);
    background: linear-gradient(166deg, rgb(238, 78, 86) 0%, rgb(239, 102, 101) 61%, rgb(239, 105, 100) 100%); */
    border: 2px solid rgb(238, 78, 86);
}

.buy_searchbody_context_5:hover{
    color: rgb(238, 78, 86);
    transition: transform 0.3s ease-out;
    transform: scale(1.02)
}

.buy_searchbody_context_6{
    /* background: -webkit-linear-gradient(282deg, rgb(42, 138, 231) 0%, rgb(57, 159, 232) 100%);
    background: -o-linear-gradient(282deg, rgb(42, 138, 231) 0%, rgb(57, 159, 232) 100%);
    background: -ms-linear-gradient(282deg, rgb(42, 138, 231) 0%, rgb(57, 159, 232) 100%);
    background: -moz-linear-gradient(282deg, rgb(42, 138, 231) 0%, rgb(57, 159, 232) 100%);
    background: linear-gradient(168deg, rgb(42, 138, 231) 0%, rgb(57, 159, 232) 100%); */
    border: 2px solid rgb(42, 138, 231);
}

.buy_searchbody_context_6:hover{
    color: rgb(42, 138, 231);
    transition: transform 0.3s ease-out;
    transform: scale(1.02)
}

.buy_sell_caption{
    /* background: rgb(253,238,224); */
    /* background: white; */
    font-size: 150%;
    border-radius: 10px;
    font-weight: bold;
    color: white;
}

.buy_sell_option{
    color: white;
    font-size: 110%;
    margin-bottom: 2%;
}

.buy_sell_button{
    background: #2E207C;
    text-align: center;
    margin-left: 20px;
    padding: 0.7% 3%;
    border-radius: 100px;
    color: white;
    border: 1px solid white;
    cursor: pointer;
}

.buy_sell_button:hover{
    background: white;
    color: #2E207C;
    border: 1px solid transparent;
}
.buy_card_outline{
    width: 22%;
    border:5px solid #F3913D;
    border-style: solid hidden hidden hidden;
    border-radius: 5px 5px 0px 0px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items:center;
    transition: transform 0.3s ease-out;
    box-shadow: 1px 1px 1.5px 1px rgba(0,0,0,0.3);
}

.buy_card_outline:hover{
    transition: transform 0.3s ease-out;
    transform: scale(1.05)
}

#buy_card_name{
    color: grey;
    font-size: 90%;
    font-weight: 500;
    margin: auto;
}

#buy_mostpopular_audiotag{
    width: 95%;
}

#buy_popular_play{
    width: 8%;
    outline: none;
}

.buy_card_text_div{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 1% 0% 3% 0%;
}

.buy_popularcard_image_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8% 1%;
}

.buy_popularcard_button_div{
    width: 100%;
    border-style: solid hidden hidden hidden;
    border-width: 1px;
    border-color: lightgrey;
    display: flex;
    justify-content: center;
    padding: 3% 0%;
    cursor: pointer;
}

.buy_popularcard_button_div:hover{
    background: #F3913D;
}

.buy_popularcard_button_div:hover button{
    color: white;
}

.buy_populartryout_button{
    background: transparent;
    border: none;
    margin-top: 1%;
}

.buy_populartryout_button:focus{
    outline: none;
}


/* Market Sell */
.sell{
    width: 100%;
    height: 100%;
    padding-bottom: 3%;
}

.sell_headerimage_textcontainer{
    width: 50%;
}

.sell_whatismarket_container{
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(255, 255, 255);
    padding: 3% 0%;
}
