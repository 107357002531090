

.homebenefits_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.benefit_container{
    display: flex;
    flex-direction: column;
    width: 20%;
    justify-content: space-between;
}

.benefit_image{
    width:100%;
    object-fit: cover;
}

#benefit_title{
    color: rgb(238, 118, 6);
    font-size: 130%;
    font-family:Verdana;
    /* text-shadow:0px 1px grey; */
    margin: 5% 0%;
}

#benefit_desc{
    color: grey;
    height: 250px;
    text-align: justify;
}

.benefit_sepearator{
    border:1px solid #F3913D;
    height: 400px;
}

.benefit_info_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width:700px){
    .homebenefits_container{
        display: flex;
        flex-direction: column;
    }

    .benefit_sepearator{
        border:1px solid #F3913D;
        width: 80%;
        height: 0%;
        margin: 5% 0%;
    }

    .benefit_container{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
    }

    .benefit_image{
        width: 100%;
        padding: 10% 0%;
        object-fit: cover;
    }

    .benefit_info_container{
        margin-right: 3%;
    }

    #benefit_desc{
        display: flex;
        align-items: center;
    }
}