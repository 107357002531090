.voices{
    width: 100%;
    height: 93vh;
    background: rgb(244,243,242);
background: linear-gradient(180deg, rgba(244,243,242,1) 19%, rgba(251,250,250,1) 27%, rgba(255,255,255,1) 100%);
    /* background: rgb(243,145,61); */
/* background: linear-gradient(180deg, rgba(243,145,61,1) 0%, rgba(246,176,115,1) 0%, rgba(251,219,192,1) 0%, rgba(253,238,224,1) 0%, rgba(255,252,249,1) 71%, rgba(255,255,255,1) 100%); */
}

.voices_section1{
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 99%;
}

.voices_section1_infolinecontainer{
    background:white;
    width:100%;
    display:flex;
    justify-content: space-between;
    padding: 1%;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}

.voices_pie_chart{
    cursor: pointer;
    width: 13%;
    margin: 0% 10%;
    align-self: flex-end;
    border-radius: 50%;
    background: white; 
    padding: 1.5%;
    box-shadow: 0px 0px 5px 3px rgba(243,145,61,0.43);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.voices_pie_modal{
    padding: 5%;
}

.voices_pie_chart:hover{
    box-shadow: 0px 0px 6px 5px rgba(243,145,61,0.43);
}

.voices_nextContent{
    cursor: pointer;
    background: rgb(238, 236, 236);
    margin: 2% 8%;
    border-radius: 10px;
    padding: 2%;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2; /* number of lines to show */
}

.voices_section2{
    height: 50%;
    display: flex;
    justify-content: center;
}

.voices_section2_prevcontent_container{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0%;
}

.voices_prevcontent_outline{
    width: 80%;
    margin: 3% 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 4% 0%;
    border-radius: 100px;
    box-shadow: 0px 0px 5px 3px rgba(243,145,61,0.43);
    cursor: pointer;
}

.voices_prevcontent_uploadingoutline{
    width: 80%;
    margin: 3% 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4% 0%;
}

.voices_prevcontenttext_uploading{
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    text-align: justify;
    font-size: 85%;
}

.voices_prevcontenttext{
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    text-align: justify;
    font-size: 85%;
}

.voices_section3{
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.voices_textbox{
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 50%;
    padding: 2%;
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}

.voices_section1_info{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    width: 50%;
}

.voices_section1_info p{
    font-style: italic;
    white-space: pre;
}

.voices_section3_button{
    /* margin-left: -23%; */
    cursor: pointer;
    height: 11.5vh;
    width: 11.5vh;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0px 0px 27px 6px rgba(243,145,61,0.43);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.voices_section3_button:hover{
    box-shadow: 0px 0px 27px 16px rgba(243,145,61,0.43);
}

.transcriptdiv_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.voices_section3_transcriptdiv{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 1%;
    border-radius: 5px;
    /* font-size: 100%; */
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}

.transcriptdiv_caption{
    width: 80%;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #F3913D;
}

.transcribed_text{
    width: 90%;
    max-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}

.transcribed_accuracycaption{
    align-self: flex-end;
    display: flex;
    align-items: center;
    font-size: 75%;
    margin-bottom: -1.5%;
}

.transcribed_accuracy{
    font-size: 150%;
    margin-top: -3%;
    margin-left: 5%;
}

.voices_button{
    pointer-events: none;
    background: white;
    padding: 0.5% 1.5%;
    border-radius: 10%;
    color: rgb(163, 157, 157);
    font-size: 90%;
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}

.voices_button_enabled:hover{
    background: white;
    color: #F3913D;
    border: 0.5px solid #F3913D;
}

.voices_button_enabled{
    background: white;
    color: #F3913D;
    cursor: pointer;
    padding: 0.5% 1.5%;
    border-radius: 10%;
    font-size: 90%;
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}

.voices_showtranscript{
    background: white;
    color: #F3913D;
    cursor: pointer;
    padding: 1.2% 1.5%;
    font-size: 90%;
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}

.voices_showtranscript:hover{
    border: 0.5px solid #F3913D;
}

.voices_waveform{
    width:70%;
    margin-left: -35%
}

.voices_waveform_line{
    margin-left: -15%;
    width:40%;
    border-bottom: 1px solid #2F2180;
}

.voices_infomodal_header{
    width:100%;
    text-align: center;
    font-size: 180%;font-weight: bold;
}