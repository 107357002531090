.editor_screen{
    width: 100%;
    height: 93vh;
    display: flex;
    background: rgb(244,243,242);
    background: linear-gradient(180deg, rgba(244,243,242,1) 19%, rgba(251,250,250,1) 27%, rgba(255,255,255,1) 100%);
}

.public-DraftEditor-content {
    padding: 5px;
    margin: 5px;
  }

.editor_section1{
    width: 25%;
    display: flex;
    flex-direction: column;
}

.editor_section1_top{
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#editor_backimage{
    width: 15%;
    background: white;
    border-radius: 50px;
    padding: 2.5%;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
}

.editor_section1_buttonscontainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 70%;
}

.editor_editbutton_container{
    cursor: pointer;
    width: 35%;
    height: 23%;
    padding: 10%;
    margin: 0% 5% 5% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 10%;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.editor_playmodal_container{
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editor_emptybuttoncontainer{
    width: 38%;
    height: 25%;
    padding: 10%;
    margin: 0% 5% 5% 5%;
}

.editor_editbutton_container:hover{
    background: white;
    transform: scale(1.05);
}

.link_textbox{
    width: 80%;
    height: 40px;
    border: 2px solid rgb(170, 170, 170);
    float: left;
    padding: 0px 15px 0px 15px;
    margin: 0px 15px 15px 15px;
}

.modal_linkContainer{
    padding: 50px;
}

#editor_editbuttonimage{
    width: 80%;
    object-fit: contain;
}

.editor_section2{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editor_section2_upper{
    height: 35%;
    width: 100%;
}

.editor_section2_lower{
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#editor_playimage{
    width: 100%;
}

.editor_playcontainer{
    width: 7%;
    padding: 1.5%;
    border-radius: 100px;
    background: white;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
}

.editor_section3{
    width: 25%;
    display: flex;
    flex-direction: column;
    padding-top: 7%;
}

.editor_section3_separator{
    height: 5%;
}

.editor_section3_dropbutton{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: lightgray;
}
.editor_section3_droptext{
    display: flex;
    justify-content: flex-start;
    width: 90%;
}

.editor_section2_sidebarcontainer{
    width: 22%;
    background: red;
}

.editor_textbox{
    background: white;
    width: 95%;
    height: 100%;
    max-height: 55%;
    padding: 2%;
    box-shadow: 1px 7px 10px 2px rgba(0,0,0,0.1);
}

.editor_section2_button{
    color: grey;
    border-radius: 5%;
    padding: 1% 3%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    box-shadow: 0px 0px 5px 3px rgba(243,145,61,0.43);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}


.editor_section2_button:hover{
    color: black;
    box-shadow: 0px 0px 10px 6px rgba(243,145,61,0.43);
}

.editor_section2_split_button{
    color: grey;
    width: 20%;
    border-radius: 5%;
    padding: 1% 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: white;
    box-shadow: 0px 0px 5px 3px rgba(243,145,61,0.43);
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin: 0% 2%;
}
.editor_section2_split_button:hover{
    color: black;
    box-shadow: 0px 0px 10px 6px rgba(243,145,61,0.43);
}


div.DraftEditor-root {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  div.DraftEditor-editorContainer,
  div.public-DraftEditor-content {
    height: 100%;
  }