.robots{
    width:100%;
    height: 100%;
    /* background: rgb(253,238,224);
    background: linear-gradient(180deg, rgba(253,238,224,1) 0%, rgba(255,255,255,1) 100%); */
}

.robots_section_container{
    width: 100%;
    height: 100%;
    padding: 5% 10% 5% 10%;
}

.robots_sell_caption{
    /* background: rgb(253,238,224); */
    /* background: white; */
    font-size: 150%;
    border-radius: 10px;
    font-weight: bold;
    color: white;
}

.robots_horizontal_divider{
    width: 100%;
    border: 1px solid;
    border-color: rgb(228, 221, 221);
    margin: 3% 0%;
}

.robots_sell_option{
    color: white;
    font-size: 110%;
    margin-bottom: 2%;
}

.robots_sell_button{
    background: #F3913D;
    text-align: center;
    margin-left: 20px;
    padding: 0.7% 3%;
    border-radius: 100px;
    color: white;
    border: 1px solid white;
    cursor: pointer;
}

.robots_sell_button:hover{
    background: white;
    color: #F3913D;
    border: 1px solid transparent;
}

.robots_sectioncaption{
    font-weight: bold;
}

.robots_flowdigram_image{
    width: 90%;
}

.robots_operating_container{
    display: flex;
    margin: 0% 0% 5% 0%;
    justify-content: center;
}

.robots_operating_card_outline{
    width: 38%;
    margin: 0% 2%;
    display: flex;
    border: 1px solid #F3913D;
}

.robots_operating_card_imagecontainer{
    width: 20%;
    display: flex;
    justify-content: center;
}

.robots_operating_card_image{
    width:100%;
    object-fit: contain;
}

.robots_operating_card_textcontainer{
    width: 80%;
    padding: 2%;
}

.robots_operating_card_title{
    font-weight: bold;
    font-size: 110%;
}

.robots_operating_card_desc{
    font-size: 99%;
}

.robots_benefits_container{
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.robots_benefits_option{
    color: grey;
    font-size: 110%;
    margin-bottom: 2%;
    text-align: left;
}

.robots_benefit_container{
    width: 30%;
    padding:0% 2%;
}

.robots_benefit_image{
    width:100%;
    object-fit: cover;
}

.robots_benefit_info_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#robots_benefit_title{
    color: rgb(238, 118, 6);
    font-size: 130%;
    font-family:Verdana;
    /* text-shadow:0px 1px grey; */
    margin: 5% 0%;
}

#robots_benefit_desc{
    color: grey;
    height: 250px;
    text-align: justify;
}

.robots_benefit_sepearator{
    border:1px solid #F3913D;
    height: 450px;
}