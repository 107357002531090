.MainDiv {
  margin: 0%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainDiv button:focus{
  outline: none;
}

.logincontainer{
  width: 80%;
  height: 80vh;
  display: flex;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}

.login_left{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F3913D;
}

#login_left_logo{
  width: 30%;
}

.login_left h4{
  color: white;
  font-weight: bold;
}

.login_right h4{
  font-weight: bold;
}

.provider_button{
  width: 50%;
  background: transparent;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin: 5% 0% 0% 0%;
  padding: 2%;
  color: #eee;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  border: 1px solid;
  cursor: pointer;
}

.provider_button:hover{
  background: white;
  color: #F3913D;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  outline: none;
}

.login_right{
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: white;
}

.login_right label{
  font-size: 0.7rem;
  margin-top: 5%;
  margin-bottom: 1%;
}

.login_right input{
  /* width: 60%; */
  border: none;
  border-bottom: 2px solid lightgrey;
  outline: none;
  font-size: 0.9rem;
}

.login_right_button{
  background: #F3913D;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin: 10% 0% 0% 0%;
  padding: 3%;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
  border: 1px solid #F3913D;
}

.login_right_button_disabled{
  background: #f9c89e;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin: 10% 0% 0% 0%;
  padding: 3%;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
  border: 1px solid #F3913D;
}

.login_right_button:hover{
  background: transparent;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  color: #F3913D;
  font-size: 1.2rem;
}

.unselected_button{
  background:#F3913D;
  width: 15%;
  margin: 0%;
  outline: none;
  border-color: lightgrey;
  font-size: 70%;
  padding: 1% 0%;
  color: white;
  font-weight: 500;
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  border-color: rgba(128, 128, 128,0.5);
  text-align: center;
  cursor: pointer;
}

.unselected_button:hover{
  background:transparent;
  color: #F3913D;
}

.selected_button{
  background: transparent;
  width: 15%;
  margin: 0%;
  border-color: #F3913D;
  color: #F3913D;
  font-size: 70%;
  padding: 1% 0%;
  font-weight: 500;
  text-align: center;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  cursor: pointer;
}

.forgotpassword{
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 500;
}

.category_button{
  background: #F3913D;
  padding: 3% 1%;
  border: 1px solid;
  border-radius: 5%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70%;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.category_button:hover{
  background: transparent;
  color: #F3913D;
}

.selected_category_button{
  background: transparent;
  padding: 1%;
  border: 1px solid;
  border-radius: 5%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70%;
  font-weight: bold;
  color: #F3913D;
}

.password_container{
  margin-top: 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.error_message{
  color: red;
  font-weight: bold;
  font-size: 0.7rem;
  margin-left: 5%;
}

@media(max-width:700px){

  .logincontainer{
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .login_left{
    width: 100%;
    padding: 10% 0%;
  }

  .login_right{
    width: 100%;
    padding: 10% 0%;
  }

}



/* 
//////////// */


.signinText {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding: 7px;
}
.dialogStyle {
  width: 680px;
  max-width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  /* z-index: 999; */
  background-color: #eee;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.dialogCloseButtonStyles {
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  width: 589px;
  height: 50px;
  align-self: center;
  background-color: #1273eb;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  font-size: 20px;
}
.paragraphSignin {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding: 1px;
  color: darkslategrey;
}
.Signinbuttons {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  padding-right: 42px;
}
.buttonGoogle {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 90px;
  background-color: white;
  color: black;
  font-size: 19px;
  cursor: pointer;
  padding-left: 60px;
  box-shadow: 1.5px 1.5px #888888;
}
.buttonFacebook {
  margin-left: 13px;
  padding: 10px;
  border-radius: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 90px;
  background-color: #3b5998;
  color: white;
  font-size: 19px;
  cursor: pointer;
  padding-left: 60px;
  box-shadow: 1.5px 1.5px #888888;
}
.googleimg {
  height: 26px;
  width: 26px;
  margin-right: 10px;
  margin-left: 10px;
}
.seperator {
  padding-bottom: 10px;
  font-style: italic;
  text-align: center;
}
.lineleft {
  height: 0.1px;
  border-color: rgb(124, 109, 109);
  width: 250px;
  margin-left: 34px;
}
.sameline {
  display: flex;
  justify-content: space-between;
}
.lineright {
  height: 0.5px;
  border-color: rgb(124, 109, 109);
  width: 250px;
  margin-right: 34px;
}
.login {
  padding-left: 30px;
  padding-right: 40px;
}
.signupandforgot {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 50px;
}
.password {
  color: blue;
  font-size: 14px;
  cursor: pointer;
}
.buttonsocialgoogle {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 80px;
  background-color: white;
  color: black;
  font-size: 19px;
  cursor: pointer;
  padding-left:50px;
  box-shadow: 1.5px 1.5px #888888;
}
.buttonsocialface {
  margin-left: 13px;
  padding: 10px;
  border-radius: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 80px;
  background-color: #3b5998;
  color: white;
  font-size: 19px;
  cursor: pointer;
  padding-left:50px;
  box-shadow: 1.5px 1.5px #888888;
}
.qwe {
  background-color: red;
}
.signinUppage {
  display: flex;
  justify-content: flex-end;
  margin-right: 14px;
  color: blue;
  cursor: pointer;
  font-size: 16px;
}
