.side_drawer{
    height: 100%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side_drawer.open{
    transform: translateX(0);
}

.side_drawer ul{
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content:center;
}

.side_drawer li{
    margin: 0.5rem 0;
}

.side_drawer a{
    color: #F3913D;
    text-decoration: none;
}

.side_drawer a:hover,
.side_drawer a:hover{
    color: rebeccapurple;
}

@media (min-width:700px){
    .side_drawer{
        display: none;
    }
}