.squarecard{
    background: white;
    /* box-shadow: 1px 0px 1px rgba(0,0,0,0.1); */
    border:0.1px solid rgba(0,0,0,0.1);
    margin:30px 30px;
    width:250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card_title{
    font-weight: 500;
    font-size: 1rem;
    color: rgba(0,0,0,0.8);
    margin: 0;
}

.card_desc{
    font-size: 0.9rem;
    color: rgba(0,0,0,0.5)
}