.celebrityvoice{
    margin: 0;
}

.celebrity_card_outline{
    width: 30%;
    border:0.1px solid rgba(0,0,0,0.1);
    background: white;
    align-items:center;
    transition: transform 0.3s ease-out;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}


#celebrity_slide_container{
    width: 100%;
    display: flex;
    justify-content: space-around;
}


.description{
    color: grey;
    font-style: italic;
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
}

/* .celebrity_card_outline:hover{
    border-color: #F3913D;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    transition: transform 0.3s ease-out;
    transform: scale(1.05)
} */

.celebrity_card_outline p{
    font-size: 90%;
    color: grey;
}

#celebrity_card_image_container{
    width: 40%;
}

#cel_card_avatar_image{
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.cel_modal_outline{
    display: flex;
}

.cel_modal_img_container{
    width: 50%;
}

.cel_modal_infocontainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 5%;
}

#cel_modal_title{
    font-size: 1.5rem;
    /* color: orange; */
}

#cel_modal_occupation{
    font-weight: bold;
    color: orange;
}

.cel_modal_image{
    width: 100%;
    object-fit: cover;
}

@media(max-width:700px){
    #card_name{
        font-size: 70%;
    }
}



.celebrity_infocontainer{
    width: 60%;
    height: 100%;
    padding: 3% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cel_name{
    color: rgba(0,0,0,1);
    font-weight: bold;
}

.cel_job{
    color: red;
}

.cel_card_text_div{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#cel_audiotag{
    width: 100%;
}

@media (max-width:700px){
    .celebrity_card_outline{
        width: 100%;
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #celebrity_slide_container{
        display: flex;
        flex-direction: column;
        padding: 0% 0%;
    }

    #celebrity_card_image_container{
        width: 30%;
    }
    
    #cel_card_avatar_image{
        width: 100%;
        object-fit: cover;
    }

    .celebrity_infocontainer{
        width: 70%;
        height: 100%;
    }
}
