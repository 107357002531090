.toolbar{
    position:fixed;
    width: 100%;
    background:white;
    height: 60px;
    top: 0%;
    left: 0%;
    z-index: 650;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}

.toolbar_avatar_image{
    width:35%;
    margin-left: 20%;
    cursor: pointer;
}
.toolbar_popover_avatar_image{
    width:14%;
    margin-left: 5%;
    cursor: pointer;
}

.toolbar_userlist_title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.toolbar_popover_listitem{
    display: flex;
    align-items: center;
    margin: 2.5% 0% 5% 0%;
}

.toolbar_popover_listitem_separator{
    width:100%;
    border: 1px solid rgb(250, 240, 240);
}

.spacer{
    flex: 1;
}

.toolbar_logo{
    /* margin-left: -20px; */
    display: flex;
    align-items: center;
}

.toolbar_logo_image{
    /* width:56px; */
    height: 60px;
}

.toolbar_logo_name{
    height: 100px;
    margin-left: -20px;
}

.toolbar_logo a{
    color: #F3913D;
    text-decoration: none;
    font-size: 1.5rem;
}

.toolbar_navigation{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.toolbar_navigation_items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
}

.toolbar_navigation_items li{
    padding: 0 0.5rem;
}

.toolbar_navigation_items a{
    color: #F3913D;
    text-decoration: none;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active{
    color: rebeccapurple;
}


@media (max-width: 700px) {
    .toolbar_navigation_items{
        display: none;
    }
}

@media (min-width:700px){
    .toolbar_toggle_button{
        display:none;
    }
}