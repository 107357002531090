.corporatevoice{
    margin: 0;
    width: 100%;
    height: 100%;
}

.mainimage_container{
    height: 400px;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    padding: 100px;
}

.mainimage_button{
    width: 18%;
    padding: 10px;
    background-color: transparent;
    font-size: 20px;
    /* border-radius: 100px; */
    color: white;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    font-size: 140%;
    align-items: center;
    text-align: center;
}

.mainimage_button:hover {
    background-color: #F3913D;
    color: white
}

.mainimage_button:focus{
    outline: none;
}

.corporate_howitworks_container{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding: 5% 2% 3% 2%;
    background: #F9F9F8;
}

.corporate_howitworks_title_container{
    width: 50%;
    display: flex;
    justify-content: center;
}

.corporate_howitworks_title_container p{
    /* color: #F3913D; */
    font-size: 150%;
    /* font-family: fantasy; */
    font-weight: bold;
}

.corporate_howitworks_desc_container{
    max-width: 50%;
}

.corporate_howitworks_desc_container p{
    /* color: #2E207C; */
    width: 80%;
}

#corporate_howitworks_readmore{
    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
    color: rgb(18, 126, 214);
}

.corporatevoice_benefits_container{
    background: #F3913D;
}

.cv_benefits_cardcontainer{
    display: flex;
    justify-content: space-around;
    padding-bottom: 5%;
}

.cv_benefits_cardoutline{
    background: white;
    width: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 2%; */
}

.cv_benefits_cardoutline p{
    text-align: center;
}

#cv_benefit_title{
    font-weight: 500;
    font-size: 90%;
}

#cv_benefit_desc{
    color: grey;
    font-size: 85%;
    text-align: justify;
    width: 100%;
    padding: 0% 5%;
}

.cv_flowdiagram_container{
    width: 100%;
}

.cv_flowdigram_image{
    width: 100%;
}

.cv_usecase_outline{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
}

.cv_usecase_imagecontainer{
    width: 40%;
    display: flex;
    justify-content: center;
}

.cv_usecase_image{
    width: 50%;
}

.cv_usecase_infocontainer{
    width: 60%;
    padding: 1% 3%;
}

.cv_usecase_title{
    font-size: 190%;
    width: 90%;
    font-weight: 500;
    /* color: #F3913D; */
}

.cv_usecaseright_title{
    font-size: 190%;
    width: 90%;
    font-weight: 500;
    text-align: end;
}

.cv_usecase_desc{
    font-size: 1.1rem;
    width: 90%;
    text-align: justify;
}

.cv_usecaseright_desc{
    font-size: 1.1rem;
    width: 90%;
    text-align: justify;
}

.cv_usecase_separator{
    height: 150px;
    border: 1px solid #F3913D;
}

.cv_blog_image{
    width: 100%;
}

.cv_blog_cardoutline{
    background: white;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-out;
}

.cv_blog_cardoutline:hover{
    transition: transform 0.3s ease-out;
    transform: scale(1.05)
}

@media (max-width:700px){

    .mainimage_container{
        height: 250px;
    }

    .mainimage_button{
        font-size: 80%;
    }

    .cv_benefits_cardcontainer{
        flex-direction: column;
        align-items: center;
    }

    .cv_benefits_cardoutline{
        width: 95%;
        margin-bottom: 4%;
        flex-direction: row;
    }

    .cv_benefits_imagecontainer{
        width: 40%;
        height: 100%;
    }

    .benefit_image{
        height: 100%;
    }

    .cv_benefits_infocontainer{
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2% 0%;
    }

    #cv_benefit_title{
        font-weight: 500;
        font-size: 90%;
    }
    
    #cv_benefit_desc{
        color: grey;
        width: 90%;
        font-size: 75%;
    }

    .cv_usecase_outline{
        flex-direction: column;
        align-items: center;
        margin-bottom: 2%;
    }

    .cv_usecase_separator{
        width: 0%;
        height: 0px;
        border: 0px solid #F3913D;
    }
    .cv_usecase_separator_bottom{
        width: 90%;
        height: 1px;
        border: 1px solid #F3913D;
        margin: 4% 0%;
    }

    .cv_usecase_imagecontainer{
        width: 90%;
        display: flex;
        justify-content: center;
    }

    .cv_usecase_infocontainer{
        width: 100%;
        padding: 1% 3%;
    }

    .cv_usecase_title{
        font-size: 130%;
        width: 100%;
        font-weight: bold;
        text-align: center;
    }

    .cv_usecase_desc{
        font-size: 98%;
        width: 100%;
        padding: 0% 2%;
        text-align: justify;
    }

    .cv_usecaseright_title{
        font-size: 130%;
        width: 100%;
        font-weight: bold;
        text-align: center;
    }

    
    .cv_usecaseright_desc{
        font-size: 98%;
        width: 100%;
        padding: 0% 2%;
        text-align: justify;
    }

    .cv_blog_cardoutline{
        background: white;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5%;
        transition: transform 0.3s ease-out;
    }
    
}